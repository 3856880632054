import { defineComponent, h, type PropType } from "vue";
import { VSelect } from "vuetify/components";
import { allCountries } from "country-region-data";
import type { RegionName, RegionSlug } from "country-region-data";
import { isString } from "lodash";
import { filterRegions } from "../../_utils";

type Region = { name: RegionName; shortCode: RegionSlug };

export default defineComponent({
  name: "VRegionSelect",
  extends: VSelect,
  props: {
    modelValue: {},
    country: { type: String },
    defaultRegion: { type: String, default: "AU" },
    returnName: { type: Boolean, default: false },
    whiteList: {
      type: Array as PropType<string[]>,
      default: (): string[] => [],
    },
    blackList: {
      type: Array as PropType<string[]>,
      default: (): string[] => [],
    },
    displayCode: { type: Boolean, default: false },
    placeholder: { type: String, default: "Select region" },
    noDataText: { type: String, default: "No region available" },
    usei18n: { type: Boolean, default: false },
    autocomplete: { type: [Boolean, String], default: true },
    menuProps: {
      type: Object,
      default: () => ({
        maxHeight: 300,
      }),
    },
  },
  computed: {
    shownRegions(): Region[] {
      if (!this.country) {
        return [];
      }

      const country = allCountries.find(([countryName, countryShortCode]) => {
        return countryShortCode == this.country || countryName == this.country;
      });

      if (!country) {
        return [];
      }

      let [_countryName, _countryShortCode, countryRegions] = country;

      const regions: Region[] = filterRegions(
        countryRegions,
        this.whitelist || [],
        this.blacklist || [],
        this.returnName
      ).map(([name, shortCode]) => ({
        name,
        shortCode,
      }));

      if (this["$i18n"] && this["$t"] && this.usei18n) {
        return regions.map((region) => {
          const localeRegion = Object.assign({}, region);
          // @ts-ignore
          localeRegion.name = this["$t"](region.name);
          return localeRegion;
        });
      }

      return regions;
    },
  },
  methods: {
    getAutocomplete() {
      if (isString(this.autocomplete)) {
        return this.autocomplete;
      }

      return this.autocomplete ? "address-level1" : "off";
    },
  },
  watch: {
    country() {
      const oldValue = this.modelValue;
      setTimeout(() => {
        if (oldValue == this.modelValue) {
          this.$emit("update:modelValue", undefined);
        }
      }, 300);
    },
  },
  render() {
    return h(VSelect, {
      ...this.$attrs,
      ...this.$props,
      items: this.shownRegions,
      itemTitle: this.displayCode ? "shortCode" : "name",
      itemValue: this.returnName ? "name" : "shortCode",
      autocomplete: this.getAutocomplete(),
      "onUpdate:modelValue": (value: any) =>
        this.$emit("update:modelValue", value),
    });
  },
});
