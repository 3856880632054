import { defineComponent, h, type PropType } from "vue";
import { VAutocomplete, VLazy, VListItem } from "vuetify/components";
import { allCountries } from "country-region-data";
import type { CountrySlug } from "country-region-data";
import { isString } from "lodash";
import { filterCountries } from "../../_utils";

export default defineComponent({
  name: "VCountrySelect",
  extends: VAutocomplete,
  props: {
    returnName: { type: Boolean, default: false },
    priority: {
      type: Array as PropType<CountrySlug[]>,
      default: () => [],
    },
    whitelist: { type: Array as PropType<CountrySlug[]>, default: () => [] },
    blacklist: { type: Array as PropType<CountrySlug[]>, default: () => [] },
    displayCode: { type: Boolean, default: false },
    placeholder: { type: String, default: "Select country" },
    noDataText: { type: String, default: "No country available" },
    usei18n: { type: Boolean, default: false },
    autocomplete: { type: [Boolean, String], default: true },
    menuProps: {
      type: Object,
      default: () => ({
        maxHeight: 300,
      }),
    },
  },
  computed: {
    countries() {
      const countries = filterCountries(
        allCountries,
        this.priority,
        this.whitelist || [],
        this.blacklist || []
      ).map(([countryName, countryShortCode, regions]) => ({
        countryName,
        countryShortCode,
        regions,
      }));

      if (this["$i18n"] && this["$t"] && this.usei18n) {
        return countries.map((country) => {
          const localeCountry = Object.assign({}, country);
          localeCountry.countryName = this["$t"](country.countryName);
          return localeCountry;
        });
      }

      return countries;
    },
  },
  methods: {
    getAutocomplete() {
      if (isString(this.autocomplete)) {
        return this.autocomplete;
      }

      return this.autocomplete
        ? this.returnName
          ? "country-name"
          : "country"
        : "off";
    },
  },
  render() {
    return h(
      VAutocomplete,
      {
        ...this.$attrs,
        ...this.$props,
        items: this.countries,
        itemTitle: this.displayCode ? "countryShortCode" : "countryName",
        itemValue: this.returnName ? "countryName" : "countryShortCode",
        autocomplete: this.getAutocomplete(),
        "onUpdate:modelValue": (value: any) =>
          this.$emit("update:modelValue", value),
      },
      {
        item: ({ item, props }: any) =>
          h(
            VLazy,
            {
              options: {
                threshold: 0.25,
              },
              minHeight: 20,
            },
            () =>
              h(VListItem, {
                ...props,
                title: item.title,
              })
          ),
      }
    );
  },
});
